import Loader from "../Views/Loader";
import { Suspense, lazy } from "react";

const HomePage = lazy(() => import("../Views/HomePage"));

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <HomePage />
    </Suspense>
  );
};

export default App;
