import "./index.sass";

const Loader = ({ laoded }) => {
  return (
    <div className={laoded ? "loader ready" : "loader"}>
      <div className="loader__block">
        <div className="loader__block--cube" id="first">
          <div className="blur" />
        </div>
        <div className="loader__block--cube" id="second">
          <div className="blur" />
        </div>
        <div className="loader__block--cube" id="third">
          <div className="blur" />
        </div>
        <div className="loader__block--cube" id="fourth">
          <div className="blur" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
